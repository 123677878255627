import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core'
import MaterialCard from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import DateFnsUtils from '@date-io/date-fns';
import { DropzoneArea } from 'material-ui-dropzone';
import { MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import { getUniqueID, unixdate } from '../../../utils/utils'
import { useWeb3React } from '@web3-react/core';
import { useStyles } from './MinterCard.styles';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import IpLogo from '../../../assets/IpLogo.png'
import MoneyLogo from '../../../assets/MoneyLogo.png'
import UUIDLogo from '../../../assets/UUIDLogo.png'
import WalletLogo from '../../../assets/WalletLogo.png'
import BasicInput from '../../inputs/BasicInput';
import useClientIP from '../../../hooks/useClientIp';
import useUploadFile from '../../../hooks/useUploadFile';
import useCurrentPrice from '../../../hooks/useCurrentPrice';
import useBalance from '../../../hooks/useBalance';
import useMintToken from '../../../hooks/useMintToken';
import useGetHash from '../../../hooks/useGetHash';
const FileType = require('file-type/browser');
const MinterCard = () => {
    const { account, chainId, library } = useWeb3React();
    const ip = useClientIP();
    const [previeuwURL, setPrevieuwURL] = React.useState('');
    const [UUID, setUUID] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [selectedUnixDate, setUnixDate] = React.useState(unixdate(new Date()));
    const [description, setDescription] = React.useState('');
    const [name, setName] = React.useState('');
    const [fullAddress, setFullAddress] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [site, setSite] = React.useState('');
    const [file, setFile] = React.useState();
    const [fileBuffer, setFileBuffer] = React.useState();
    const [ipfsHash, setIpfsHash] = React.useState();
    const [formValid, setFormValid] = React.useState(true)
    const { onMint } = useMintToken(
        UUID,
        title,
        description,
        ipfsHash,
        selectedUnixDate,
        name,
        fullAddress,
        email,
        site,
        ip
    );

    const { hash, onUpload } = useUploadFile(fileBuffer, title, UUID);
    const reader = new FileReader();
    const fee = useCurrentPrice();
    const userBalance = useBalance();
    const classes = useStyles();
    const previeuwHash = useGetHash(fileBuffer)
    const [fileType, setFileType] = React.useState();

    const onClear = (e) => {
        setUUID(getUniqueID())
        handleDelete()
        setSelectedDate(new Date());
        setDescription('');
        setEmail('');
        setSite('');
        setUnixDate(unixdate(new Date()));
        setTitle('');
        setFileType('');
        setFullAddress('');
        setName('');
    }

    const onClearFields = (e) => {
        setFormValid(true);
        setSelectedDate(new Date());
        setDescription('');
        setEmail('');
        setSite('');
        setUnixDate(unixdate(new Date()));
        setTitle('');
        setFileType('');
        setFullAddress('');
        setName('');
    }

    const handleValidations = () => {
        if (title === '' || description === '' || name === '' || fullAddress === '' || email === '' || site === ''){
            setFormValid(false);
            return (false);
        }
        else {
            setFormValid(true);
            return (true)
        }
    }

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setUnixDate(unixdate(date))
    };

    const handleChangeFile = (newfiles) => {
        setFile(newfiles[0])
        handleFile(newfiles[0]);
    };

    const handleDelete = deleted => {
        setFile(undefined);
        setFileBuffer(null);
        setIpfsHash(undefined);
        setPrevieuwURL('');
        onClearFields();
    };

    const handleMint = (e) => {
        if (handleValidations()) {
            onMint().then(
                onClear()
            );
        }
    };

    const handleFile = (file) => {
        if (file !== undefined) {
            reader.readAsArrayBuffer(file);
            reader.onloadend = () => {
                const buffer = Buffer(reader.result)
                setFileBuffer(buffer);
            }
        }
    };

    const handleFileUpload = () => {
        onUpload();
    }

    React.useEffect(() => {
        if (file !== undefined) {
            const objectURL = URL.createObjectURL(file);
            setPrevieuwURL(objectURL);
            (async () => {
                const response = await fetch(objectURL);
                const type = await FileType.fromStream(response.body);
                setFileType(type ? type.mime : '');
                // console.log(type ? type.mime : '');
                //=> {ext: 'jpg', mime: 'image/jpeg'}
            })();
        }
        if (UUID === ''){
            setUUID(getUniqueID())
        }
        if (hash) {
            // console.log(hash)
            setIpfsHash(hash);
        }

    }, [account, hash, file, fileBuffer])


    return (
        <MaterialCard className={classes.card}>
            <Grid
                container
                spacing={3}
                justify='center'
            >
                <Grid item xs={12}>
                    <CardHeader
                        title={
                            <Typography variant='h6' gutterBottom color='textPrimary' className={classes.subheader}>
                                <strong>HASH FROM FILE: {file ? previeuwHash : ''}</strong>
                            </Typography>}
                        subheader={
                            <Grid container className={classes.subheader + " info-box"} >
                                <Grid container item lg={6} alignItems='center' spacing={1}  >
                                    <Grid item >
                                        <img src={UUIDLogo} className={classes.avatar} />
                                    </Grid>
                                    <Grid item >
                                        <Typography variant='h5' color='primary'>
                                            {UUID}
                                        </Typography>
                                    </Grid>
                                    <Grid item  >
                                        <Tooltip
                                            title={
                                                <Typography variant='body1'>Unique ID number of the document. Please put this somewhere inside your file for verification</Typography>
                                            }>
                                            <IconButton size='small' >
                                                <HelpIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid container item lg={6} alignItems='center' spacing={1} >
                                    <Grid item >
                                        <img src={IpLogo} className={classes.avatar} />
                                    </Grid>
                                    <Grid item >
                                        <Typography variant='h6'>
                                            {ip}
                                        </Typography>

                                    </Grid>
                                    <Grid item >
                                        <Tooltip
                                            title={
                                                <Typography variant='body1'>We use the IP of you computer as a identifier.</Typography>
                                            }>
                                            <IconButton size='small' >
                                                <HelpIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>


                                </Grid>
                                <Grid container item lg={6} alignItems='center' spacing={1}>
                                    <Grid item >
                                        <img src={MoneyLogo} className={classes.avatar} />
                                    </Grid>
                                    <Grid item >
                                        <Typography variant='h6' noWrap>
                                            {fee ? library.utils.fromWei(fee, 'ether') : 'Loading...'} ETH
                                        </Typography>
                                    </Grid>
                                    <Grid item >
                                        <Tooltip
                                            title={
                                                <Typography variant='body1'>Fee to be paid in ETH (5 USD) in addition to gas fees.</Typography>
                                            }>
                                            <IconButton size='small' >
                                                <HelpIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>


                                </Grid>
                                <Grid container item lg={6} alignItems='center' spacing={1}>
                                    <Grid item >
                                        <img src={WalletLogo} className={classes.avatar} />
                                    </Grid>
                                    <Grid item >
                                        <Typography variant='h6' noWrap>
                                            {userBalance ? library.utils.fromWei(userBalance, 'ether') : 'Loading...'} ETH
                                        </Typography>
                                    </Grid>
                                    <Grid item >
                                        <Tooltip
                                            title={
                                                <Typography variant='body1'>Connected wallet balance</Typography>
                                            }>
                                            <IconButton size='small' >
                                                <HelpIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        action={
                            <>
                                <Button onClick={handleDelete} color="secondary" variant="contained" size='medium'>
                                    <Typography variant='subtitle1' >
                                        RESET &nbsp;
                                    </Typography>
                                    <RotateLeftIcon />
                                </Button>
                            </>
                        }

                    />
                </Grid>
                <Grid
                    container
                    spacing={2}
                    className={classes.content + " form-box"}
                >
                    <Grid item xs={12} >
                        <BasicInput
                            type='text'
                            label='Token Title'
                            value={title}
                            onChange={(e) => { setTitle(e.target.value)}}
                            variant="outlined"
                            required
                            error={!formValid}
                            helperText={(!formValid && title === '') ? 'Token Title is required' : ''}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <BasicInput
                            type='text'
                            label='Description'
                            value={description}
                            onChange={(e) => { setDescription(e.target.value) }}
                            error={!formValid}
                            helperText={(!formValid && description === '') ? 'Description is required' : ''}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <BasicInput
                            type='text'
                            label='Name'
                            value={name}
                            onChange={(e) => { setName(e.target.value) }}
                            error={!formValid}
                            helperText={(!formValid && name === '') ? 'Name is required' : ''}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <BasicInput
                            type='text'
                            label='Address'
                            value={fullAddress}
                            onChange={(e) => { setFullAddress(e.target.value) }}
                            error={!formValid}
                            helperText={(!formValid && fullAddress === '') ? 'Address is required' : ''}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <BasicInput
                            type='text'
                            label='Contact E-Mail'
                            value={email}
                            onChange={(e) => { setEmail(e.target.value) }}
                            error={!formValid}
                            helperText={(!formValid && email === '') ? 'Contact E-Mail is required' : ''}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <BasicInput
                            type='text'
                            label='Reference Website'
                            value={site}
                            onChange={(e) => { setSite(e.target.value) }}
                            error={!formValid}
                            helperText={(!formValid && site === '') ? 'Reference Website is required' : ''}
                        />
                    </Grid>
                    <Grid container item xs={12} justify='center'>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardDatePicker
                                fullWidth={true}
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Valid until"
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid container item xs={12} className={classes.preview} >
                        <Grid item xs={12}>
                            {fileType === 'application/pdf' ?
                                <object data={previeuwURL} className={classes.preview} />
                                :
                                <img src={previeuwURL} className={classes.preview} />
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <DropzoneArea
                            acceptedFiles={['image/png', 'image/jpg', 'image/jpeg', 'image/svg', 'application/pdf', 'video/mov']}
                            showPreviews={false}
                            showPreviewsInDropzone={false}
                            onChange={handleChangeFile}
                            onDelete={handleDelete}
                            maxFileSize={1048576000}
                            filesLimit={1}
                        />
                    </Grid>
                    <Grid container item xs={12} >
                        <Grid item xs={12}>
                            * Accepted files: .jpg / .jpeg / .png / .pdf / .mov
                       </Grid>
                    </Grid>
                    <Grid item xs={12} >
                        <Button
                            variant='contained'
                            fullWidth={true}
                            color='primary'
                            size='large'
                            disabled={ipfsHash !== undefined ? true : false}
                            onClick={handleFileUpload}
                        >
                            Upload to IPFS
                        </Button>
                    </Grid>
                    <Grid item xs={12} >
                        <Button
                            variant='contained'
                            fullWidth={true}
                            color='primary'
                            size='large'
                            disabled={ipfsHash != undefined ? false : true}
                            onClick={handleMint}
                        >
                            Mint Token
                        </Button>
                    </Grid>
                </Grid>

            </Grid>

        </MaterialCard>
    )
}

export default MinterCard; 