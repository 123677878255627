import React from 'react'
import { AppBar, Button, Grid, Modal, Toolbar, Typography } from '@material-ui/core'
import { useWeb3React } from '@web3-react/core';
import { Link } from 'react-router-dom'
import { useStyles } from './Header.styles'

import useBalance from '../../hooks/useBalance';
import WalletModal from '../walletmodal/WalletModal.component'
import Box from "@material-ui/core/Box";
import { isMobile } from "react-device-detect";
import Container from "@material-ui/core/Container";

const WalletButton = ({ provider, loadWeb3Modal, logoutOfWeb3Modal }) => {
  const { account } = useWeb3React();
  const [open, setOpen] = React.useState(false);
  const [isShown, setIsShown] = React.useState(false)
  const classes = useStyles();

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div>
      <Button
        variant={account ? 'outlined' : 'contained'}
        size="large"
        color="primary"
        className={classes.button}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        onClick={
          document.body.classList.contains('web3-installed') ?
          () => {
            if (!provider) {
              handleOpen()
              loadWeb3Modal();
            } else if (!isMobile){
              logoutOfWeb3Modal();
            }
          }

          :

          console.log('Metamask not found')
        }
      >
        {!provider ?
          <>
            {(document.body.classList.contains('web3-installed') !== true && isMobile) ?
              <Typography variant="body2" noWrap>
                <strong>
                  <a href="https://metamask.app.link/dapp/www.generator.pink/#/create">Open in Metamask</a>
                </strong>
              </Typography>
              :
              <Typography variant="body2" noWrap>
                <strong>Connect Wallet</strong>
              </Typography>
            }</>
          :
          <Grid container item spacing={2} direction="row" className={classes.nowrapper}>
            {isShown == true ?
              <>
                <Grid item>
                  <Typography variant="body2">
                    🟢
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" noWrap>
                    <Link href="/">
                      <strong>Disconnect Wallet</strong>
                    </Link>
                  </Typography>
                </Grid>
              </>
              :
              <>
                <Grid item>
                  <Typography variant="body2">
                    🟢
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" noWrap>
                    {account ? account.slice(0, 16) : '0x'}...
                  </Typography>
                </Grid>
              </>
            }
          </Grid>


        }
      </Button>
      <Modal
        style={getModalStyle()}
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <>
          <WalletModal
            handleClose={handleClose}
          />
        </>
      </Modal>
    </div>
  )
};

const Header = ({ nav1, nav2, provider, loadWeb3Modal, logoutOfWeb3Modal }) => {
  const { account } = useWeb3React();
  const accountBalance = useBalance();
  const classes = useStyles();

  React.useEffect(() => {

  }, [account, accountBalance])

  return (
    <AppBar position='static' elevation={0}>
      <Toolbar className={classes.header}>
        <Typography
          component={Link}
          to={`/`}
          color="textPrimary"
          variant="h3"
          gutterBottom={!account}
        >
          <strong>Generator <span style={{ color: '#FF0A99' }}>.</span></strong>
        </Typography>

        <div className={classes.grow}></div>
        {account ? <div className={classes.links}>
          <Box p={2}>
            <Typography
              className={classes.title}
              component={Link}
              to={`/${nav1}`}
              color="textPrimary"
              variant="h5"
            >
              {nav1}
            </Typography>
          </Box>
          <Box p={2}><Typography
            className={classes.title}
            component={Link}
            to={`/${nav2}`}
            color="textPrimary"
            variant="h5"
          >
            {nav2}
          </Typography></Box>
        </div> : ""}
        {/* {isMobile ? '' :
            <WalletButton provider={provider} loadWeb3Modal={loadWeb3Modal} logoutOfWeb3Modal={logoutOfWeb3Modal}/>
          } */}
        <WalletButton provider={provider} loadWeb3Modal={loadWeb3Modal} logoutOfWeb3Modal={logoutOfWeb3Modal} />
      </Toolbar>
    </AppBar>
  )
};

export default Header;
