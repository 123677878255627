import { theme } from '../../theme'
import {
  makeStyles,
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.main,
    minHeight: '85vh',
    '& h4': {
      fontWeight: 'bold',
    }
  },
  hero: {
    padding: '150px',
    margin: 0,
    width: '100%',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      '& > .MuiGrid-root': {
        padding: '10px 0',
      },
      '& h1': {
        fontSize: '10vw',
      }
    },
    [theme.breakpoints.down('sm')]: {
      background: '-webkit-linear-gradient(top, #e5e5e5 0%,#ffffff 100%)',
      filter: " progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5e5e5', endColorstr='#ffffff',GradientType=0 )",
      padding: '30px 20px',
      '& h1': {
        fontSize: '10vw',
      },
      '& h4, & h5': {
        textAlign: 'center',
      }
    }
  },
  subcontainer: {
    padding: '15px 150px 15px 150px',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.subcontainer.dark,
  },
  subscription: {
    padding: '50px 30px',
    margin: 0,
    width: '100%',
    backgroundColor: theme.palette.subcontainer.light,
    [theme.breakpoints.down('sm')]: {
      background: '-webkit-linear-gradient(top, #e5e5e5 0%,#ffffff 100%)',
      filter: " progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5e5e5', endColorstr='#ffffff',GradientType=0 )",
      padding: '30px 10px',
      '& h4, & h5': {
        textAlign: 'center',
      }
    }
  },
  mintercontainer: {
    margin: '0 0 0 10px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      '& li': {
        paddingLeft: 0,
      }
    }
  },
  darkcontainer: {
    padding: '15px 150px 15px 150px',
    margin: 0,
    width: '100%',
    color: theme.palette.text.light,
    background: theme.palette.subcontainer.dark,
    [theme.breakpoints.down('sm')]: {
      padding: '30px 20px',
      textAlign: 'center',
      '&:not(.text)': {
        background: '-webkit-linear-gradient(top, #3f4c6b 0%,#606c88 100%);',
        filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#3f4c6b', endColorstr='#606c88',GradientType=0 )",
      },
      '&.text':{
        background: 'none',
        '& .MuiGrid-item': {
          flex: '0 0 100%',
          '& h5': {
            lineHeight: '1.5',
          }
        },
      },
      '& > .MuiGrid-root': {
        padding: '5px 0',
      },
      '& .MuiButton-root': {
        margin: '5px auto 0',
      }
    }
  },
  box: {
    height: '100%',
    margin: '20px',
    padding: '10px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: 0,
    }
  },
  button: {
    borderRadius: '15px',
    minHeight: '80px',
    minWidth: '280px',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
  },
  backgroundVideo: {
    position: 'fixed', zIndex: '-99', width: '100vw', height: '100vh'
  },
  textcontainer: {
    margin: '40px',
    padding: '40px',
    backgroundColor: theme.palette.subcontainer.light,
    minHeight: '600px',
    [theme.breakpoints.down('sm')]: {
      background: '#fff',
      padding: '20px!important',
      margin: '0 0 15px',
      minHeight: 0,
    }
  },
})
);
