import React from 'react';
import { 
    Grid,
    Typography,
    Button,
    Link as MaterialLink,
    ButtonGroup,
    Modal,
    TextField,
    Divider
} from '@material-ui/core'

import { convertTimestamp } from '../../../utils/utils'

import LinearProgress from '@material-ui/core/LinearProgress';

import MaterialCard from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import { useWeb3React } from '@web3-react/core';
import { useStyles } from './ItemCard.styles';

import useGetDocument from '../../../hooks/useGetDocument';
import useGetURI from '../../../hooks/useGetURI';
import useBurn from '../../../hooks/useBurn'
import useTransfer from '../../../hooks/useTransfer'
import useAllowance from '../../../hooks/useAllowance';
import useApprove from '../../../hooks/useApprove';

const FileType = require('file-type/browser');



const ItemCard = ({id}) => {
    const {account, chainId, library } = useWeb3React();

    const item = useGetDocument(id);
    const uri = useGetURI(id);
    const [ fileType, setFileType ] = React.useState('');
    const [ reciverer, setReciver ] = React.useState('');
    const { onTransfer } = useTransfer(id, reciverer);
    const [ open, setOpen ] = React.useState(false);
    const [ isShown, setIsShown ] = React.useState(false);
    const { onBurn } = useBurn(id, item.uri);
    const classes = useStyles();
    const allowance = useAllowance(id);
    const { onApprove } = useApprove(id);

    function getModalStyle() {
        const top = 50;
        const left = 50;
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const handleOpen = () => {
        setOpen(true);
      };
    
    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(()=>{
        console.log(item);
       if(uri !== undefined) {
        (async () => {
            const response = await fetch(uri);
            const type = await FileType.fromStream(response.body);
            setFileType(type ? type.mime : '');
            // console.log(type ? type.mime : '');
            //=> {ext: 'jpg', mime: 'image/jpeg'}
        })();
       }
    }, [item, chainId, account, id, uri, allowance])


    return (
        <MaterialCard className={classes.card}>
            <Grid 
                container
                spacing={2}
                justify='center'
                alignItems='center'
                className={classes.content}
            >  

            {item ? 
            <>
                <CardHeader
                    title={
                        <Typography variant='h6'>
                          <strong>Token ID: {id}</strong>
                        </Typography>
                      
                    }
                />
                <Grid container item >
                    <Grid item md={3} xs={6}>
                        <Typography variant='h6'>
                            <strong>Created:</strong>
                        </Typography>
                        <Typography variant='h6' color='primary'>
                            {item ? convertTimestamp(item.timestamp) : <LinearProgress color="primary" />}
                        </Typography>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Typography variant='h6'>
                            <strong>Validation:</strong>
                        </Typography>
                        <Typography variant='h6' color='primary'>
                            {item ? convertTimestamp(item.validationDate) : <LinearProgress color="primary" />}
                        </Typography>
                    </Grid>            
                    <Grid item md={3} xs={6}>
                        <Typography variant='h6'>
                            <strong>UUID:</strong>
                        </Typography>
                        <Typography variant='h6' color='primary'>
                            {item ? item.uuid : <LinearProgress color="primary" />}
                        </Typography>
                    </Grid> 
                    <Grid item md={3} xs={6}>
                        <Typography variant='h6'>
                            <strong>IP:</strong>
                        </Typography>
                        <Typography variant='h6' color='primary'>
                            {item ? item.ipaddress : <LinearProgress color="primary" />}
                        </Typography>
                    </Grid>   
                </Grid>
                <Grid container item >

                        <Grid item xs={12}>
                            {fileType === 'application/pdf' ? 
                                <object data={uri} className={classes.pdf} />
                            :
                                <img src={uri} className={classes.file}/>
                            }
                                            
                        </Grid>   

                </Grid> 
                <Grid container item className={classes.FormDetails}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                        <strong>Title:</strong>
                        </Typography>
                    </Grid>        
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            {item ? item.title : <LinearProgress color="primary" />}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                        <strong>Description: </strong>
                        </Typography>
                    </Grid>    
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            {item ? item.description : <LinearProgress color="primary" />}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                        <strong>Link:</strong>
                        </Typography>
                    </Grid>    
                    <Grid item xs={12}>
                        <Typography component={MaterialLink} target='_blank' href={uri ? uri : ''} variant='body1'>
                            {uri ? (uri.slice(0,64)+'...') : <LinearProgress color="primary" />}
                        </Typography> 
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                        <strong>Hash:</strong>
                        </Typography>
                    </Grid>    
                    <Grid item xs={12}>
                        <Typography  variant='h6'>
                            {item ? item.uri : <LinearProgress color="primary" />}
                        </Typography> 
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                        <strong>Name:</strong>
                        </Typography>
                    </Grid>    
                    <Grid item xs={12}>
                        <Typography  variant='h6'>
                            {item ? item.name : <LinearProgress color="primary" />}
                        </Typography> 
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                        <strong>Address:</strong>
                        </Typography>
                    </Grid>    
                    <Grid item xs={12}>
                        <Typography  variant='h6'>
                            {item ? item.fulladdress : <LinearProgress color="primary" />}
                        </Typography> 
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                        <strong>Website:</strong>
                        </Typography>
                    </Grid>    
                    <Grid item xs={12}>
                        <Typography  variant='h6'>
                            {item ? item.website : <LinearProgress color="primary" />}
                        </Typography> 
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            <strong>Email:</strong>
                        </Typography>
                    </Grid>    
                    <Grid item xs={12}>
                        <Typography  variant='h6'>
                            {item ? item.email : <LinearProgress color="primary" />}
                        </Typography> 
                    </Grid>
                </Grid>
            </>
            :
                <LinearProgress color="primary" />
            
            }
            <Grid container item >
                <ButtonGroup fullWidth={true}>
                    <Button onClick={handleOpen} color="primary"> Transfer </Button>
                    <Button onClick={onBurn}  > Burn </Button>
                </ButtonGroup>
            </Grid>
        </Grid>
        <Modal
            style={getModalStyle()}
            className={classes.modal}
            open={open}
            onClose={handleClose}
        >
            <MaterialCard className={classes.card} style={{height:'auto'}}>
                <TextField
                    size='small'
                    onChange={(e)=>{setReciver(e.target.value)}}
                    variant='outlined'
                    type='text'
                    helperText='Reciever Address'
                />
                
                <Button  onClick={onTransfer} variant='outlined' color='primary' className={classes.button}> SEND </Button>
            </MaterialCard>
        </Modal> 
        </MaterialCard>
    )
}

export default ItemCard; 