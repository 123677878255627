import React, {Fragment} from "react";
import {Grid, Link, Typography} from '@material-ui/core'
import {useStyles} from './About.styles'
import Box from "@material-ui/core/Box";
import {isMobile} from "react-device-detect";
import Container from "@material-ui/core/Container";

function About() {

  const classes = useStyles();

  return (
    <Fragment>
      {/* {isMobile ?
        <Box className={classes.mobilediv} p={2}>
          Service does not work yet on mobile devices. NFT creation service is optimized for Chrome browser with
          Metamask
          wallet plug in.
        </Box> : ''
      } */}
      <Container maxWidth="lg">
        <Grid
          container
          direction='column'
          alignItems='center'
          className={classes.hero}
        >
          <Grid item xs={12} className={classes.bordersubtile}>
            <Typography variant="h5">
              More
            </Typography>
          </Grid>
          <Grid item xs={12} spacing={6} className={classes.textcontainer}>
            <Grid item xs={12}>
              <Grid item xs>
                <Typography variant='h3' color='secondary' className={classes.title}>
                  01/
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='h4' gutterBottom>
                  What is this service about?
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2'>
                  Generator provides a service to generate custom NFTs (non-fungible tokens) on Ethereum Blockchain in
                  the
                  ERC-721 standard.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs>
                <Typography variant='h3' color='secondary' className={classes.title}>
                  02/
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='h4' gutterBottom>
                  What is the use-case?
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2'>
                  The use case can be derived from the fact that Generator creates digital objects that can no longer be
                  changed and or copied after creation. They are uniquely assigned to a user, and they can be sent like
                  an
                  e-mail. No specific platform is required to trade NFTs. They are traded peer-to-peer. This makes NFTs
                  ideal carriers of bearer certificates of all kinds. In the art sector, NFTs are traded as bearer
                  certificates for digital files with sales in the millions.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs>
                <Typography variant='h3' color='secondary' className={classes.title}>
                  03/
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='h4' gutterBottom>
                  How does creation of an NFT work?
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2'>
                  First, you connect your Metamask wallet (www.metamas.io) with the page. Then, you upload your file,
                  enter relevant meta-data and hit the MINT-button.
                  After confirmation of the fees the systems generates your NFT based on the uploaded file. The token is
                  stored in your wallet.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs>
                <Typography variant='h3' color='secondary' className={classes.title}>
                  04/
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='h4' gutterBottom>
                  How can I view my NFTs?
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2'>
                  If the page is connected with your wallet the page will display all your tokens in the wallet on the
                  page.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs>
                <Typography variant='h3' color='secondary' className={classes.title}>
                  05/
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='h4' gutterBottom>
                  How can I transfer my NFTs to a new owner?
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2'>
                  Connect your wallet with the page and go to the relevant token on your wallet. Hit the transfer
                  button.
                  Enter the target wallet address in the pop-up menu. Confirm transaction. Done. Your token is now
                  transferred to the target wallet.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs>
                <Typography variant='h3' color='secondary' className={classes.title}>
                  06/
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='h4' gutterBottom>
                  Can I delete an NFT?
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2'>
                  Yes. Connect your wallet with the page and go to the relevant token on your wallet. Hit the delete
                  button. Confirm transaction. Done. Your token is now deleted.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs>
                <Typography variant='h3' color='secondary' className={classes.title}>
                  07/
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='h4' gutterBottom>
                  What are the fees?
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2'>
                  The majority of the fees are charged by the Ethereum blockchain for each tokenization. Depending on
                  the
                  Ether (ETH) and gas price, the fee can fluctuate around 100 USD. Generator.pink charges a fixed
                  overhead
                  of 5,- USD per NFT creation. There is no risk: Before the final transaction, the fee is displayed in
                  the
                  wallet and must be confirmed before it is debited.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs>
                <Typography variant='h3' color='secondary' className={classes.title}>
                  08/
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='h4' gutterBottom>
                  Do the NFTs have programmed royalties?
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2'>
                  No. When the NFTs are traded on the secondary market, no fee goes to the issuer or Generator.pink.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs>
                <Typography variant='h3' color='secondary' className={classes.title}>
                  09/
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='h4' gutterBottom>
                  What data are stored on the NFT?
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2'>
                  Token title, token ID, short description, link to the file which is stored on IPFS, the hash value of
                  the file, the company/name and address of the issuer, reference website of the issuer, email of the
                  issuer, creation date, validity date of the token, a UUID for reference, the IP address of the
                  creator.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs>
                <Typography variant='h3' color='secondary' className={classes.title}>
                  10/
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='h4' gutterBottom>
                  Is the uploaded file saved on the blockchain?
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2'>
                  No. The blockchain is not designed to save large data volumes. So the file is saved to IPFS but the
                  hash
                  value of the file (fingerprint) is saved on the blockchain.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs>
                <Typography variant='h3' color='secondary' className={classes.title}>
                  11/
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='h4' gutterBottom>
                  What are the system requirements?
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2'>
                  Generator.pink works best with Google Chrome browser on desktop with Metamask Wallet (Metamask.io). It
                  does not yet work for mobile devices yet!
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs>
                <Typography variant='h3' color='secondary' className={classes.title}>
                  12/
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='h4' gutterBottom>
                  Can I test the service for free?
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2'>
                  Yes you can. Change your wallet to the KOVAN-test blockchain and the NFTs will be generated there.
                  Also,
                  the fees are charged in worthless KOVAN ETH which means the test service is for free. If you don’t
                  have
                  KOVAN ETH in your wallet, please email to admin@generator.pink including your wallet address. KOVAN
                  ETH
                  will be sent to you for free.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs>
                <Typography variant='h3' color='secondary' className={classes.title}>
                  13/
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='h4' gutterBottom>
                  More questions?
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2'>
                  Reach out to <Link color='textPrimary' href='mailto:admin@generator.pink'
                                     target='_blank'>admin@generator.pink</Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
}

export default About;
