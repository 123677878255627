import { theme } from '../../theme'
import { 
  makeStyles,
} from '@material-ui/core/styles';

export const useStyles = makeStyles({
  modal: {   
    borderRadius: '15px',
    transform: 'none!important',
    inset: 'none!important',
  },
  card:{
    padding: '20px',
    width: '80%',
    maxWidth: 500,
    borderRadius: '15px',
    backgroundColor: theme.palette.paper.main,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]:{
      '& .MuiGrid-item':{
        padding: '0',
        marginBottom: 10,
        '& .MuiButton-root': {
          marginBottom: 5,
        }
      }
    }
  }
});