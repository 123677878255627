import { theme } from '../../../theme'
import { 
  makeStyles,
} from '@material-ui/core/styles';

export const useStyles = makeStyles({
    card: {
        minHeight: '50px',
        backgroundColor: theme.palette.paper.main,
        color: theme.palette.text.primary,
        borderRadius : "15px",
        padding: "30px",
        height: 'auto',
        [theme.breakpoints.down('xs')]: {
            padding: '20px',
        },
    },
    content: {
     
    },
    subheader: {
        padding: '10px'
    },
    nowrapper: {
        display: 'flex',
        flexWrap: 'nowrap'
    },
    file: {
        maxHeight: '400px',
        width:'100%',
        objectFit: 'contain'
    },
    pdf: {
        height: '400px',
        width:'100%',
        objectFit: 'contain' 
    },
    modal: {
        zIndex: '1',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        transform: 'none!important',
        inset: 'none!important',
        left: '0!important',
        top: '0!important',
    },
    button: {
        minHeight:'30px'
    },
    FormDetails:{
        '& > div:nth-child(even)':{
            marginBottom: 10,
            whiteSpace: 'normal',
            wordBreak: 'break-all'
        }
    }
});