import { theme } from '../../../theme'
import { 
  makeStyles,
} from '@material-ui/core/styles';

export const useStyles = makeStyles({
    card: {
        backgroundColor: theme.palette.paper.main,
        color: theme.palette.text.primary,
        borderRadius : "15px",
        padding: "10px",
        [theme.breakpoints.down('xs')]: {
            '& .MuiCardHeader-root':{
                padding: 5,
                flexDirection: 'column',
                '& .MuiCardHeader-action':{
                    display: 'inline-flex',
                    order: -1,
                    margin: '-5px -10px 10px',
                    alignSelf: 'stretch',
                    justifyContent: 'space-between',
                }
            }
        },
    },
    content: {
        padding: '50px',
        [theme.breakpoints.down('lg')]: {
            padding: '0 30px',
            '&.form-box':{
                paddingBottom: 30,
                [theme.breakpoints.down('xs')]: {
                    paddingBottom: '0 20px',
                },
            },
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0 20px',
        },
    },
    subheader: {
        padding: '0 50px 0 50px',
        color: theme.palette.text.primary,
        [theme.breakpoints.down('lg')]: {
            padding: 0,
            '&.info-box > div':{
                marginBottom: 5,
            },
        },
        [theme.breakpoints.down('xs')]: {
            '& h5':{
                fontSize: 18,
            },
            '& h6':{
                fontSize: 18,
            }
        },
    },
    nowrapper: {
        display: 'flex',
        flexWrap: 'nowrap'
    },
    dropzone:{
        height: '100px'
    },
    preview: {
        maxHeight: '400px',
        width:'100%',
        objectFit: 'contain'
    },
    avatar:{
        height:'25px',
        width: '25px',
        [theme.breakpoints.down('xs')]: {
            height:'20px',
            width: '20px',
        },
    }
});