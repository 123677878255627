
import {
  createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';



const _theme = createMuiTheme({
  root: {
     display: 'flex',
     alignItems: 'center',
     justifyContent:'center'
  },
  typography: {
    h1: {
       fontWeight: '400'
    },
    h2: {

    },
    h3: {

    },
    h4: {

    },
    h5: {

    },
    h6: {

    },
    fontFamily: 'Nunito Sans'
  },

  palette: {
      type: 'light',

      primary: {
        main: '#FF0A99',
      },
      secondary: {
        main: "#119DA4" ,
      },
      background: {
        main: '#fffff',
      },
      backgroundDark: {
        main: '#2C2B2B',
      },
      header: {
        main: "#fffff"
      },
      subcontainer: {
        light: "#f2f2f2",
        dark: "#2C2B2B"
      },

      footer: {
        main: "#F3F5F4"
      },
      paper: {
          main: '#f0f0f0'
      },
      text: {
          primary: "#141414",
          secondary: "#191919",
          light: '#fafafa'
      },
  },
  overrides: {
    MuiButton:{
      root: {
        '& .MuiButton-label':{
          fontWeight: 600,
        }
      }
    },
    MuiTooltip:{
      '& .MuiTooltip-popper':{
        fontSize: 14
      }
    },
    MuiFormControl:{
      root:{
        '& .MuiInputLabel-root':{
          fontSize: 18,
        },
        '& legend':{
          padding: '0 5px',
        }
      }
    }

  }
});

export const theme = responsiveFontSizes(_theme);



