import { theme } from '../../theme'
import {
    makeStyles,
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    root: {
        maxWidth: '80vw'
    },
    header: {
        zIndex: '0',
        padding: '10px 150px 10px 150px',
        backgroundColor: theme.palette.backgroundDark.main,
        minHeight: '5vh',
        flexGrow: 1,
        '& a': {
            color: theme.palette.text.light,
        },
        [theme.breakpoints.down('lg')]: {
            padding: '15px 30px',
        },
        [theme.breakpoints.down('sm')]:{
            background: '-webkit-linear-gradient(top, #3f4c6b 0%,#606c88 100%);',
            filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#3f4c6b', endColorstr='#606c88',GradientType=0 )",
        },
        [theme.breakpoints.down('xs')]: {
            padding: 15,
            flexDirection: 'column',
        }
    },
    grow: {
        flexGrow: 1,
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    links: {
        display: 'flex',
    },
    title: {
        zIndex: '0',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.text.light,
        fontVariantCaps: 'all-small-caps',
        '&:hover': {
            color: theme.palette.primary.main,
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    wrapper: {
        zIndex: '0',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    button: {
        zIndex: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '30px',
    },
    nowrapper: {
        zIndex: '0',
        display: 'flex',
        flexWrap: 'nowrap',
    },
    modal: {
        zIndex: '1',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        transform: 'none!important',
        inset: '0!important',
        boxSize: 'border-box',
    },

}));
