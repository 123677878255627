import { theme } from '../../theme'
import { 
  makeStyles,
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    root: {
        '& .MuiFormControlLabel-root':{
            marginRight: 0,
        },
        '& .MuiFormControl-root':{
            '& legend':{
                padding: 0,
            }
        }
    },

    button:{
        height: '38px',
        width: '100px',
        marginLeft: 10,
        backgroundColor: theme.palette.subcontainer.dark,
        color: theme.palette.text.light
    },
}));