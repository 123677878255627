import { theme } from '../../theme'
import { 
  makeStyles,
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    footer: {
        zIndex: '0',
        backgroundColor: theme.palette.backgroundDark.main,
        borderTop: `3px solid ${theme.palette.primary.main}`,
        [theme.breakpoints.down('sm')]:{
            background: '-webkit-linear-gradient(top, #3f4c6b 0%,#606c88 100%);',
            filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#3f4c6b', endColorstr='#606c88',GradientType=0 )",
        }
    },
    footerContent:{
        padding: 15,
        [theme.breakpoints.down('sm')]:{
            '& > div':{
                justifyContent: 'center',
            }
        }
    },
    title: {
        color: theme.palette.text.light,
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    nowrapper: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modal: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed'
    },
    avatar: {
        height: '45px',
        width: '45px',
        filter: 'invert(1)',
    }
}));